import React from "react"
import { graphql } from "gatsby"

import { BLUE, TIER_ONE } from "../constants"

import Layout from "../components/Layout/Layout"
import FlexibleContent from "../components/FlexibleContent"
import Hero from "../components/Hero/Hero"
import ServicesCard from "../components/Services/ServicesCard"

const Services = ({ data, location }) => {
  const {
    metaDescription,
    metaTitle,
    headingPrimary,
    headingSecondary,
    heroHeading,
    body,
    title,
    tierOneImage,
    tierOneMobileImage,
    flexibleContent,
    serviceCategories,
    signatureName,
    signatureText,
  } = data.craftServicesIndexServicesIndexEntry

  return (
    <Layout
      title={metaTitle || title}
      description={metaDescription}
      theme={BLUE}
      location={location}
    >
      <div className="js-transition-link-target" data-theme={BLUE}>
        <Hero
          heroStyle={TIER_ONE}
          heroHeading={heroHeading}
          tierOneImage={tierOneImage ? tierOneImage : undefined}
          tierOneMobileImage={
            tierOneMobileImage ? tierOneMobileImage : undefined
          }
          theme={BLUE}
          signatureName={signatureName}
          signatureText={signatureText}
        />
        <div className="px-ogs py-20 lg:py-30 md:grid md:grid-cols-12 md:gap-x-gs">
          <div className="md:col-span-8 md:col-start-3 lg:col-span-6 lg:col-start-4 text-center">
            {headingPrimary && (
              <h2 className="font-semibold text-4xl leading-4">
                {headingPrimary}
              </h2>
            )}
            {headingSecondary && (
              <p className="text-xl mt-11 font-semibold leading-8">
                {headingSecondary}
              </p>
            )}
            {body && (
              <p className="lg:mx-12 mt-5 font-medium leading-5">{body}</p>
            )}
          </div>

          {serviceCategories.length > 0 && (
            <div className="md:col-span-12 mt-15 lg:mt-24">
              <div className="flex flex-wrap justify-center gap-y-8 lg:gap-y-24 -mr-gs">
                {serviceCategories.map((item, index) => {
                  return <ServicesCard key={item.title + index} data={item} />
                })}
              </div>
            </div>
          )}
        </div>
        <FlexibleContent flexibleContent={flexibleContent} />
      </div>
    </Layout>
  )
}

export default Services

export const pageQuery = graphql`
  query {
    craftServicesIndexServicesIndexEntry {
      title
      headingPrimary
      headingSecondary
      heroHeading
      signatureName
      signatureText
      tierOneImage: heroImage {
        ... on Craft_images_Asset {
          id
          title
          alt
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 80, aspectRatio: 1.77)
            }
          }
        }
      }
      tierOneMobileImage: heroImageSecondary {
        ... on Craft_images_Asset {
          id
          title
          alt
          localFile {
            childImageSharp {
              gatsbyImageData(quality: 80, aspectRatio: 1)
            }
          }
        }
      }
      body
      flexibleContent {
        ...flexibleContentFragment
      }
      metaDescription
      metaTitle
      body
      serviceCategories {
        ... on Craft_serviceCategories_default_Entry {
          id
          title
          body
          slug
          imagePrimary {
            ... on Craft_images_Asset {
              ...imageFragment
            }
          }
        }
      }
    }
  }
`
